import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {  userLogin } from '../services';
import LoadingSpinnerOverlay from './LoadingSpinnerOverlay';

const Login = ({setIsAuthenticated}) => {
    const [loading, setLoading] = useState(false);
	const history = useHistory();
    const [errors, setErrors] = useState('');
	const onSubmit = async (event) => {
        event.preventDefault();
		setLoading(true);
		let loginReqBody = {
			email: event.target.email.value,
			password: event.target.password.value,
			app_version: "6.3.1",
			device: "WebApp"
		}
		try {
            const result = await userLogin(loginReqBody);
			if (result.success) {
				if (result.token) {
                    localStorage.setItem('accessToken', result.token);
                	setLoading(false);
                    setIsAuthenticated(true);
                    history.push(`/rtmp`);
				}
			} else {
                setErrors(true);
				setLoading(false);
            }
		} catch (error) {
            setErrors(true);
			setLoading(false);
		}
    }

    return (
        <div>
            <div className={`form-block-wrapper form-block-wrapper--is-login`} ></div>
            <section className={`form-block form-block--is-login ver-hor-align`}>
              <div className="os-logo">
                <a href="https://onestream.live" target="_blank" rel="noreferrer">
                  <img src="https://onestreamassets.b-cdn.net/webassets/website/onestream-logos-website/logo-nav.png" alt="OneStream Logo"/>
                </a>
              </div>
              <hr className="hr-styling"/>
              <header className="form-block__header">
                  <h3 className="h3_class">Please enter email & password to login</h3>
              </header>

              <form onSubmit={(event) => onSubmit(event)}>
                  <div className="form-block__input-wrapper">
                      <div className="form-group form-group--signup">
                          <input name="email" className="form-group__input" placeholder="Email" type="email" required={true} id="email" label="email"/>
                          <input name="password" className="form-group__input" placeholder="Password" type="password" required={true} id="createpassword" label="password"/>
                      </div>
                  </div>
                  <button className="button button--primary full-width" type="submit">SIGN IN</button>
                  {errors && <h2>Couldn't login. Your Email and Password is wrong</h2> }
              </form>
            </section>
        </div>
    );
}

export default Login;
