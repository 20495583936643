import './App.css';
import Login from './containers/Login';
import Rtmp from './containers/Rtmp';
import {useState, useEffect}  from 'react';
import LoadingSpinnerOverlay from './containers/LoadingSpinnerOverlay';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";



function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);

  const authenticate = async () => {
    setLoading(true);
    setIsAuthenticated(true);
    setLoading(false);
  }

  useEffect(() => {
    authenticate();
  },[])

  return (
    <div className={`app app--is-login`}>
    {loading && <LoadingSpinnerOverlay loadingText="loading..." />}
    <Router>
      <Switch>
        <Route exact path="/login" > 
         <Login setIsAuthenticated={setIsAuthenticated}/>
        </Route>
        {isAuthenticated  &&  <Route exact path="/rtmp" component={Rtmp} />}
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
    </div>
  );
}

export default App;
