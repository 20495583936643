import React from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { fetchRtmp, signOut } from "../services/index";
import LoadingSpinnerOverlay from "./LoadingSpinnerOverlay";

function Rtmp() {
  const [streamKey, setStreamKey] = useState("");
  const [serverUrl, setServerUrl] = useState("");
  const [upgradeSubscription, setUpgradeSubscription] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchRtmpPromise = fetchRtmp();
    fetchRtmpPromise.then((channelDetails) => {
      if (channelDetails?.streamKey && channelDetails?.defaultIngest) {
        let rtmpURL = null;
        if (channelDetails.defaultIngest instanceof Object) {
          rtmpURL = channelDetails.defaultIngest.rtmp;
        } else rtmpURL = channelDetails.defaultIngest;
        setServerUrl(rtmpURL);
        setStreamKey(channelDetails?.streamKey);
        setLoading(false);
      } else {
        setLoading(false);
        setUpgradeSubscription(true);
      }
    })
    .catch((error) => {
      setLoading(false);
      setUpgradeSubscription(true);
    })
  }, []);

  return (
    <Wrapper>
      <section className={`form-block form-block--is-login ver-hor-align`}>
          <div className="os-logo">
            <a href="https://onestream.live" target="_blank" rel="noopener noreferrer">
              <img className="max-width-logo" src="https://onestreamassets.b-cdn.net/webassets/dashboard-newStream/onestream_and_webex.png" alt="OneStream Logo"/>
            </a>
          </div>
          <h3 className="h3_webex">Webex Configuration</h3>

          <hr className="hr-styling"/>

          {upgradeSubscription && !loading && (
            <>
              <form>
                <div className="sub-div">
                  <span className="sub-txt">Your current OneStream subscription does not allow RTMP streaming</span>
                </div>
                <div className="btn-div ">
                  <button className="sub-plan-btn" onClick={() => window.open("https://onestream.live/pricing.html", "_blank")}>OneStream Subscription Plans <i className="fa fa-external-link"/></button>
                </div>
              </form>
            </>
          )}


          {!loading && streamKey && (
            <>
              <header className="form-block__header">
                    <ul className="webex-ul">
                    <li className="webex-li">
                      Enable Live Streaming in Webex meetings & events
                      <a
                        className="learn-more"
                        rel="noopener noreferrer"
                        href="https://help.webex.com/en-US/article/xk37so/Enable-Live-Streaming-in-Webex-Meetings-and-Events"
                        target="_blank"
                      >
                        Learn More &rarr;
                      </a>
                    </li>
                    <li className="webex-li">
                      Using the Server URL & Stream Key below configure your Webex meetings or events to send your stream directly to OneStream to multicast
                      <a
                        className="learn-more"
                        rel="noopener noreferrer"
                        href="https://help.webex.com/en-US/article/a6cmw/Live-stream-your-Webex-meetings-or-events#Cisco_Task_in_List_GUI.dita_7153b6c4-0c3e-4a84-8bba-b32d1860e877"
                        target="_blank"
                      >
                        Learn More &rarr;
                      </a>
                    </li>
                  </ul>
              </header>
              <form>
                <div className="form-block__input-wrapper">

                </div>
              </form>

              <form>
                <div className="form-block__input-wrapper">
                    <div className="form-group form-group--signup mrn-tp">

                      <span className="rtmp-dtls-head">
                        <i className="fa fa-globe"/>
                        <span className="mrgn-lft">Server URL</span>
                      </span>
                      <span className="rtmp_details">{serverUrl}</span>


                      <span className="rtmp-dtls-head">
                        <i className="fa fa-key"/>
                        <span className="mrgn-lft">Stream Key</span>
                      </span>
                      <span className="rtmp_details">{streamKey}</span>
                    </div>
                </div>
              </form>
            </>
          )}
          <div className="btn-div ">
            <button className="logout-btn" onClick={() => signOut()}>Sign out</button>
          </div>
      </section>



      {loading && <LoadingSpinnerOverlay loadingText="Fetching..." />}

    </Wrapper>
  );
}

const Wrapper = styled.div`
  .webex-li {
    margin-bottom:10px;
    font-size: 12px;
    color: #8f8f8f;
  }
  .webex-ul {
    margin-left: -16px;
  }
  .learn-more {
    text-transform: uppercase;
    font-size: 9px;
    background: gray !important;
    color: white !important;
    border-radius: 3px;
    padding-top: 0px;
    padding-right: 4px;
    padding-bottom: 0px;
    padding-left: 4px;
    margin-left: 4px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
  }
  .rtmp_details {
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    padding: 14px 9px;
    margin-bottom: 15px;
    background: rgba(255,255,255,.25);
    border: 1.5px solid #dcdcdc;
    border-radius: 3px;
    position: relative;
    display: table;
    border-collapse: separate;
    color: #000000;
  }
  .mrn-tp {
    margin-top: 40px;
  }
  .btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-left: 20px;
  }
  .logout-btn {
    cursor: pointer;
  }
  .rtmp-dtls-head {
    font-size: 12px;
    color: #858585;
  }
  .mrgn-lft {
     margin-left: 5px;
  }
  .h3_webex {
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
    font-size: 15px;
    color: #000000f7;
    letter-spacing: 0px;
    word-spacing: 1px;
    text-transform: uppercase;
  }
  .max-width-logo {
    max-width: 180px;
  }
  .sub-div {
    border: 1px solid #ebe5e5;
    border-radius: 5px;
    padding: 17px 12px;
    text-align: center;
  }
  .sub-txt {
    font-size: 13px;
    color: black;
  }
  .sub-plan-btn {
    background: linear-gradient(270deg, #a11453 0%, #e1163c 100%);
    border: none;
    color: white;
    padding: 4px 11px;
    border-radius: 4px;
    font-weight: 400;
    cursor: pointer;
  }

`;

export default Rtmp;
