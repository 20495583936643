import { API_BASE_URL } from '../config';
export const userLogin = (data) => {
    const promise = (resolve, reject) => {
      fetch(`${API_BASE_URL}/account/email/authenticate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(result => resolve(result))
        .catch(error => reject(error))
    }
  
    return new Promise(promise);
  }
  export const fetchRtmp = () => {
    const { accessToken } = localStorage;
    const promise = ((resolve, reject) => {
      fetch(`${API_BASE_URL}/live/channel`, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json'
        },
     })
     .then(res => res.json())
     .then(result => resolve(result))
     .catch(error => reject(error))
    })

    return new Promise(promise);
  };


export const signOut = () => {
    localStorage.clear();
    window.location.href = '/';
}
